import React from 'react';
import CommonHero from '../common/CommonHero';
import ContactSection from './ContactSection';
import ContactForm from './ContactForm';

function Contact() {
  return (
    <div className="">
      <CommonHero subTitle={'Home / Contact Us'} title={'Contact Us'} />

      <div className="flex flex-col gap-10">
        <div className="flex flex-col md:flex-row gap-20 px-2 md:px-52 py-5 md:py-20">
          <div className="w-full md:w-[70%] shadow-xl hover:shadow-2xl p-10">
            <ContactForm />
          </div>
          <div className="w-full md:w-[30%] shadow-xl hover:shadow-2xl p-10">
            <ContactSection />
          </div>
        </div>

        <div>
          <iframe
            title="map"
            className="w-full h-[600px]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.93751332465695!2d80.22057452764159!3d13.035658124824568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266e2b17547d5%3A0x53a7d062dc3a42bf!2sLake%20View%20Rd%2C%20Postal%20Colony%2C%20West%20Mambalam%2C%20Chennai%2C%20Tamil%20Nadu%20600033!5e0!3m2!1sen!2sin!4v1708880108237!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
