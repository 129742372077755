import React from 'react';
import { Link } from 'react-router-dom';

function Footer1() {
  return (
    <div className="bg-one flex flex-row gap-2 px-10 md:px-32 py-10 items-center justify-between mt-20">
      <div className="flex flex-col text-two">
        <span className="text-[15px] md:text-[35px] font-medium">
          VITALITY IN HARMONY{''}
        </span>
        <span className="text-[10px] md:text-[20px]">
          Nourish Your Health, Wellness, and Spirit
        </span>
      </div>
      <Link to={'/contact'}>
        <button className="bg-two text-one text-[12px] md:text-[30px] px-3 md:px-16 py-3 rounded-2xl">
          Contact
        </button>
      </Link>
    </div>
  );
}

export default Footer1;
