import React from 'react';
import photo from '../../assets/about.jpg';
import { BsPersonCheckFill } from 'react-icons/bs';
import { MdSavings, MdOutlineDoneOutline } from 'react-icons/md';

function TeamSection() {
  return (
    <div className="flex flex-col items-center justify-center gap-10 pt-20">
      <div className="flex flex-col gap-4 text-center">
        <span className="text-[40px] font-semibold">Our Team</span>
      </div>

      <div className="w-auto md:w-[50%] flex flex-row gap-10 px-5 md:px-20 py-5">
        <div className="flex flex-col text-center gap-4">
          <span className="font-semibold">
            Behind MountainTree is a team of passionate individuals who share a
            common vision of promoting health, peace, and wellness. Each of us
            has embarked on our own journey of discovery and transformation,
            driven by the desire to create a space that not only sells products
            but also shares knowledge, experiences, and the beauty of a balanced
            life.
          </span>
          <span className="text-gray-700">
            We are committed to sustainability, ethically sourcing our products,
            and partnering with artisans and companies that align with our
            values. In doing so, we strive to make a positive impact on the
            environment and the communities we touch.
          </span>
        </div>
      </div>
    </div>
  );
}

export default TeamSection;
