import React from 'react';
import { Link } from 'react-router-dom';

function Footer3() {
  return (
    <div className="text-[10px] md:text-[15px] bg-three flex flex-col items-center justify-center gap-5">
      <div className="w-full flex flex-col md:flex-row items-center justify-between text-gray-400 gap-10 px-4 md:px-24 py-10">
        <div>
          <ul className="flex flex-row gap-5">
            <Link to={'/about'}>About Us</Link>
            <Link to={'/services'}>Products</Link>
            <Link to={'/blogs'}>Blogs</Link>
            <Link to={'/contact'}>Contact Us</Link>
          </ul>
        </div>
        <div>© 2023 Copyrights | All rights reserved.</div>
      </div>

      <Link
        to={'https://sigillieum.com'}
        className="hidden text-[23px] text-white pb-6 hover:text-one duration-500"
      >
        Sigillieum
      </Link>
    </div>
  );
}

export default Footer3;
