import React from 'react';
import CommonHero from '../common/CommonHero';
import { Link } from 'react-router-dom';

function Services() {
  const data = [
    {
      id: 1,
      img: '/assets/images/products/1/1.jpg',
      title: 'Miniature Gifts',
    },
    {
      id: 2,
      img: '/assets/images/products/2/1.jpg',
      title: 'Eco-Friendly Yoga Mats',
    },
    {
      id: 3,
      img: '/assets/images/products/3/1.jpg',
      title: 'Meditation Cushions',
    },
    {
      id: 4,
      img: '/assets/images/products/4/1.jpg',
      title: 'Aromatic Essential Oils',
    },
    {
      id: 5,
      img: '/assets/images/products/5/1.jpg',
      title: 'Wellness Teas',
    },
    {
      id: 6,
      img: '/assets/images/products/6/1.jpg',
      title: 'Spiritual Decor',
    },
    {
      id: 7,
      img: '/assets/images/products/7/1.jpg',
      title: 'Crystals and Gemstones',
    },
    {
      id: 8,
      img: '/assets/images/products/8/1.jpg',
      title: 'Eco-Friendly Apparel',
    },
    {
      id: 9,
      img: '/assets/images/products/9/1.jpg',
      title: 'Sustainable Water Bottles',
    },
    {
      id: 10,
      img: '/assets/images/products/10/1.jpg',
      title: 'Mindfulness Journals',
    },
    {
      id: 11,
      img: '/assets/images/products/11/1.jpg',
      title: 'Sacred Geometry Jewelry',
    },
    {
      id: 12,
      img: '/assets/images/products/12/1.jpg',
      title: 'Incense and Smudge Sticks',
    },
  ];

  return (
    <div className="items-center justify-center">
      <CommonHero subTitle={'Home / Products'} title={'Products'} />

      <div className="flex flex-col items-center justify-center mt-20">
        <div className="w-[80%] grid grid-cols-1 md:grid-cols-3 gap-10">
          {data.map((item, i) => {
            return (
              <Link
              // to={`/services/${item.id}`}
              // className="shadow-xl hover:shadow-2xl p-4 flex flex-col items-center gap-4 rounded-[10px] cursor-pointer duration-500"
              >
                <img
                  className="h-[200px] w-[300px] object-cover rounded-[10px]"
                  src={item.img}
                  alt=""
                />
                <span className="text-[20px] font-semibold">{item.title}</span>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Services;
