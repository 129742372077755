import React from 'react';
import { Link } from 'react-router-dom';

function MoreService() {
  const data = [
    {
      id: 1,
      img: '/assets/images/products/1/1.jpg',
      title: 'Miniature Gifts',
    },
    {
      id: 2,
      img: '/assets/images/products/2/1.jpg',
      title: 'Eco-Friendly Yoga Mats',
    },
    {
      id: 4,
      img: '/assets/images/products/4/1.jpg',
      title: 'Aromatic Essential Oils',
    },
    {
      id: 5,
      img: '/assets/images/products/5/1.jpg',
      title: 'Wellness Teas',
    },
  ];

  return (
    <div className="flex flex-col items-center gap-10 justify-center mt-10 ">
      <span className="text-[25px] md:text-[40px] font-semibold">
        Recommended Products from Us
      </span>
      <div className="w-[80%] grid grid-cols-1 md:grid-cols-4 gap-10">
        {data.map((item, i) => {
          return (
            <Link
              to={`/services/${item.id}`}
              className="shadow-xl hover:shadow-2xl p-4 flex flex-col items-center gap-4 rounded-[10px] cursor-pointer duration-500"
            >
              <img
                className="h-[200px] w-[300px] object-cover rounded-[10px]"
                src={item.img}
                alt=""
              />
              <span className="text-[20px] font-semibold">{item.title}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default MoreService;
