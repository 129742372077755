module.exports = [
  {
    id: 1,
    img: '/assets/images/products/1/1.jpg',
    title: 'Miniature Gifts',
    description:
      'These gifts can serve as delightful reminders of the moments and memories that matter most, all encapsulated in a form that fits in the palm of your hand. Explore our selection and find the perfect miniature gift that captures the essence of thoughtfulness and creativity.',
    aboutTitle: 'A World of Wonder in Tiny Details ',
    aboutDescription:
      'Delve into the delightful realm of miniature gifts, where every piece is a testament to craftsmanship and imagination. Our collection of miniature gifts is a curated assortment of tiny treasures that range from intricately detailed miniature books and petite, lifelike potted plants to miniature figurines and dainty jewelry pieces. Each item is crafted with meticulous attention to detail, offering a unique charm that celebrates the beauty of the miniature world.Whether you are looking for a thoughtful gift that speaks volumes in its petite size or seeking to add a touch of whimsy to your decor, our miniature gifts are perfect for any occasion. From the pages of tiny books that tell stories in just a few words to the delicate leaves of miniature plants that bring life to any space, these gifts are designed to inspire wonder and joy.Our collection is ideal for collectors, enthusiasts, and anyone fascinated by the magic of miniatures.',
  },
  {
    id: 2,
    img: '/assets/images/products/2/1.jpg',
    title: 'Eco-Friendly Yoga Mats',
    description:
      'Rental Management (Property Management Services) is a specialized division established by My Property Plus to specifically cater to the needs of NRIs and individuals residing outside Chennai who have invested in properties in the city. We understand the unique challenges and requirements faced by non-resident property owners and strive to provide comprehensive solutions to meet their needs.We strive to provide professional, reliable, and personalized services to safeguard your investment and maximize returns.',
    aboutTitle: 'Rental Management (Property Management Services)',
    aboutDescription:
      'Tailored Solutions for NRIs and Non-Residents Investing in Chennai',
  },
  {
    id: 3,
    img: '/assets/images/products/3/1.jpg',
    title: 'Meditation Cushions',
    description:
      'Amidst the highly competitive real estate market in Chennai, My Property Plus stands out as a premier provider of Property Management (Rental Assistance Services). Our commitment to excellence and customer satisfaction sets us apart from the rest. We pride ourselves on delivering exceptional property management solutions that cater specifically to the needs of both local clients and NRIs investing in properties in Chennai.Trust My Property Plus to be your partner in managing your property investments in Chennai.',
    aboutTitle: 'Serviced (or) Guest House Managment (Non-Commercial Houses)',
    aboutDescription:
      'We understand that attention to detail and proactive measures are essential in maintaining the overall quality and satisfaction of property owners, residents, and guests alike.',
  },
  {
    id: 4,
    img: '/assets/images/products/4/1.jpg',
    title: 'Aromatic Essential Oils',
    description:
      'At My Property Plus, we go the extra mile to provide comprehensive assistance for property buyers and sellers in Chennai. Our expertise, market knowledge, and commitment to customer satisfaction make us the ideal choice for all your property buying or selling needs. Trust us to guide you through the process with professionalism, transparency, and a focus on achieving your goals.',
    aboutTitle: 'Residential Property Management',
    aboutDescription:
      'Background tenant screening, luxuries apartment management, rent collection and more.',
  },
  {
    id: 5,
    img: '/assets/images/products/5/1.jpg',
    title: 'Wellness Teas',
    description:
      "Residential Plot Management (Non-Commercial) is another specialized service offered by MY PROPERTY PLUS. We understand the importance of ensuring the well-being and security of your residential plot, and our dedicated team is here to provide constant monitoring and support.MY PROPERTY PLUS's Residential Plot Management (Non-Commercial) service, you can rest assured that your residential plot is in capable hands. Our constant monitoring, transparent reporting, and dedicated support enable you to focus on other aspects of your life while we take care of the management and well-being of your valuable asset.",
    aboutTitle: 'Plot Monitoring',
    aboutDescription:
      'Regular monitoring of plots to ensure it is free from any encroachment.',
  },
  {
    id: 6,
    img: '/assets/images/products/6/1.jpg',
    title: 'Spiritual Decor',
    description:
      'Property Maintenance Assistance is a valuable service offered by MY PROPERTY PLUS, providing you with a convenient and efficient solution for all your property maintenance needs. By outsourcing your property maintenance to us, you can enjoy a range of operational benefits while ensuring your property remains in optimal condition.Take advantage of our Property Maintenance Assistance service to streamline your property maintenance processes, optimize operational efficiency, and enjoy the benefits of a well-maintained property without the hassle.',
    aboutTitle: 'Residential Services',
    aboutDescription:
      'We handle specialized residential maintenance services to keep the property fully functional and operating at its best.',
  },
  {
    id: 7,
    img: '/assets/images/products/7/1.jpg',
    title: 'Crystals and Gemstones',
    description:
      "At MY PROPERTY PLUS, we offer top-notch Renovation Services to help you transform your property into the space you've always envisioned. Whether you're looking to update your home, upgrade your office, or enhance your commercial space, our experienced team is here to bring your renovation ideas to life.When it comes to transforming your property through renovation, trust MY PROPERTY PLUS to deliver exceptional results. Our Renovation Services combine creativity, expertise, and professionalism to bring your vision to life. Let us turn your property into a dream space that reflects your style and enhances its value and functionality.",
    aboutTitle: ' Exceptional Renovation Services',
    aboutDescription:
      'creating a space that is both aesthetically pleasing and functional. Trust us to revitalize your space and make it truly remarkable.',
  },
  {
    id: 8,
    img: '/assets/images/products/8/1.jpg',
    title: 'Eco-Friendly Apparel',
    description:
      'At MY PROPERTY PLUS, we understand that the kitchen is the heart of any home, and it requires special attention to maintain cleanliness and hygiene. Our Kitchen Cleaning Services offer a comprehensive package that ensures your kitchen receives the utmost care and attention from our team of professional experts.Trust MY PROPERTY PLUS to restore your kitchen to its pristine state, providing you with a clean and inviting space where you can prepare meals and create lasting memories.',
    aboutTitle: 'Residential Kitchen Cleaning',
    aboutDescription:
      'Get deep cleansing of your kitchen done, grout cleaning, shaft cleaning, greasy exhaust fan cleaning, kitchen hood cleaning, the burners and more. We use bio degradable products and materials to avoid any chemical hazard.',
  },
  {
    id: 9,
    img: '/assets/images/products/9/1.jpg',
    title: 'Sustainable Water Bottles',
    description:
      'At MY PROPERTY PLUS, we offer reliable and efficient Tank Cleaning Services to ensure the cleanliness and safety of your storage tanks. Whether you have water tanks, fuel tanks, or any other type of storage tanks, our professional team is equipped to handle the task with utmost care and expertise. We are committed to providing you with a clean and safe environment, ensuring the integrity and longevity of your tanks. Contact us today to schedule a professional tank cleaning service tailored to your needs.',
    aboutTitle: 'Tank Cleaning Services',
    aboutDescription:
      'Thorough, Safe, and Reliable. Our skilled team ensures clean and safe tanks by removing contaminants and sediment, maintaining a well-maintained storage environment.',
  },
  {
    id: 10,
    img: '/assets/images/products/9/1.jpg',
    title: 'Mindfulness Journals',
    description:
      'At MY PROPERTY PLUS, we offer reliable and efficient Tank Cleaning Services to ensure the cleanliness and safety of your storage tanks. Whether you have water tanks, fuel tanks, or any other type of storage tanks, our professional team is equipped to handle the task with utmost care and expertise. We are committed to providing you with a clean and safe environment, ensuring the integrity and longevity of your tanks. Contact us today to schedule a professional tank cleaning service tailored to your needs.',
    aboutTitle: 'Tank Cleaning Services',
    aboutDescription:
      'Thorough, Safe, and Reliable. Our skilled team ensures clean and safe tanks by removing contaminants and sediment, maintaining a well-maintained storage environment.',
  },
  {
    id: 11,
    img: '/assets/images/products/9/1.jpg',
    title: 'Sacred Geometry Jewelry',
    description:
      'At MY PROPERTY PLUS, we offer reliable and efficient Tank Cleaning Services to ensure the cleanliness and safety of your storage tanks. Whether you have water tanks, fuel tanks, or any other type of storage tanks, our professional team is equipped to handle the task with utmost care and expertise. We are committed to providing you with a clean and safe environment, ensuring the integrity and longevity of your tanks. Contact us today to schedule a professional tank cleaning service tailored to your needs.',
    aboutTitle: 'Tank Cleaning Services',
    aboutDescription:
      'Thorough, Safe, and Reliable. Our skilled team ensures clean and safe tanks by removing contaminants and sediment, maintaining a well-maintained storage environment.',
  },
  {
    id: 12,
    img: '/assets/images/products/9/1.jpg',
    title: 'Incense and Smudge Sticks',
    description:
      'At MY PROPERTY PLUS, we offer reliable and efficient Tank Cleaning Services to ensure the cleanliness and safety of your storage tanks. Whether you have water tanks, fuel tanks, or any other type of storage tanks, our professional team is equipped to handle the task with utmost care and expertise. We are committed to providing you with a clean and safe environment, ensuring the integrity and longevity of your tanks. Contact us today to schedule a professional tank cleaning service tailored to your needs.',
    aboutTitle: 'Tank Cleaning Services',
    aboutDescription:
      'Thorough, Safe, and Reliable. Our skilled team ensures clean and safe tanks by removing contaminants and sediment, maintaining a well-maintained storage environment.',
  },
];
