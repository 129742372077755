import React from 'react';
import GridCard from '../common/GridCard';
import { usePropertyContext } from '../../context/property_context';

function FeatureSection() {
  const { features } = usePropertyContext();

  console.log('features', features);
  return (
    <div className="flex flex-col items-center justify-center pb-10 pt-10">
      <div className="flex flex-col gap-4 text-center">
        <span className="text-[30px] md:text-[40px] font-semibold">
          Featured Products
        </span>
        <span className="text-[15px] md:text-[20px] text-gray-600">
          Handpicked just for you
        </span>
      </div>
      <div className="w-auto md:w-[50%] flex flex-row gap-10 px-5 md:px-20 py-5">
        <div className="flex flex-col text-center gap-4">
          <span className="font-semibold">
            Our roots are deeply planted in the belief that wellness transcends
            physical health, encompassing mental clarity, emotional balance, and
            spiritual fulfillment. This holistic approach inspires our
            selection, ensuring each item in our store serves a purpose in your
            journey towards achieving peace, tranquility, and vitality.
          </span>
          <span className="text-gray-700">
            At MountainTree, we're deeply committed to nurturing connections
            that transcend the boundaries of the self, reaching into the heart
            of the world around us. Our meticulously selected range of products
            is crafted to enhance these vital connections, bringing to you an
            array of eco-conscious yoga mats, plush meditation cushions,
            fragrant essential oils, rejuvenating wellness teas, and spiritually
            enriching decor. Each item in our collection is a testament to the
            spirit of tranquility and mindfulness, designed not just to adorn
            your space but to enrich your soul and foster a deep, lasting bond
            with the earth and its energy.
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-10">
        {features &&
          features.map((data, i) => {
            return (
              <GridCard
                id={data?._id}
                photo={data?.images[0]}
                catagory={data?.category}
                name={data?.name}
                address={data?.address}
                bedrooms={data?.bedrooms}
                bathrooms={data?.bathrooms}
                propertySize={data?.propertySize}
                date={data?.date}
              />
            );
          })}
      </div>
    </div>
  );
}

export default FeatureSection;
