import React from 'react';
import photo from '../../assets/products/hero.jpg';
import { Link } from 'react-router-dom';

function ServiceHero(props) {
  return (
    <div
      className="flex flex-col gap-4 items-start justify-start px-4 md:px-32 py-32 text-white"
      style={{
        backgroundImage: `url(${photo})`,
        backgroundSize: 'cover',
        overflow: 'hidden',
      }}
    >
      <span className="text-[30px] md:text-[65px] font-bold">
        {props.title}
      </span>
      <span className="text-[15px] md:text-[20px]">{props.description}</span>
      <Link
        to={'/contact'}
        className="mt-4 px-10 py-3 border-2 border-white hover:bg-white hover:text-black duration-500 rounded-[40px]"
      >
        Get A Free Quote
      </Link>
    </div>
  );
}

export default ServiceHero;
