import React from 'react';
import Hero2 from './Hero2';
import FeatureSection from './FeatureSection';

function Home() {
  return (
    <div>
      <Hero2 />
      <FeatureSection />
    </div>
  );
}

export default Home;
