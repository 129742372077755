import React, { useEffect, useState } from 'react';
import logo from './logo.png';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { MdCall, MdEmail } from 'react-icons/md';

function Navbar() {
  const location = useLocation();
  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let arr = location.pathname.split('/');
    console.log(arr);
    if (arr[1] === '') setActive(0);
    else if (arr[1] === 'about') setActive(1);
    else if (arr[1] === 'team') setActive(2);
    else if (arr[1] === 'products') setActive(3);
    else if (arr[1] === 'contact') setActive(4);
  }, [location]);

  useEffect(() => {
    setOpen(false);
  }, [active]);

  const linkClasses = (i) => {
    if (i === active) return 'text-[17px] md:bg-one px-4 py-2';
    else if (i === 7)
      return 'text-[17px] px-2 py-2 mx-2 text-one hover:text-black';
    else if (i === 8)
      return 'text-[17px] px-2 py-2 mx-2 text-one hover:text-black sm:hidden md:visible';
    return 'text-[17px] px-2 rounded-2xl py-2 mx-2';
  };
  return (
    <div className="shadow-md w-full top-0 left-0 overflow-hidden z-[999]">
      <div className="md:flex items-center justify-between bg-white md:px-10 px-7 py-2">
        <Link to={'/'} className="md:w-[150px]">
          <img className="w-[150px]" src={logo} />
        </Link>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
        >
          {open ? <AiOutlineClose /> : <FiMenu />}
        </div>

        <ul
          className={`${
            open ? 'top-[65px]' : 'top-[-690px]'
          } md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease`}
        >
          <Link
            to={'/'}
            onClick={() => setActive(0)}
            className={linkClasses(0)}
          >
            <li>Home</li>
          </Link>
          <Link
            to={'/about'}
            onClick={() => setActive(1)}
            className={linkClasses(1)}
          >
            <li>About Us</li>
          </Link>
          <Link
            to={'/team'}
            onClick={() => setActive(2)}
            className={linkClasses(2)}
          >
            <li>Team</li>
          </Link>
          <Link
            to={'/products'}
            onClick={() => setActive(3)}
            className={linkClasses(3)}
          >
            <li>Products</li>
          </Link>
          <Link
            to={'/contact'}
            onClick={() => setActive(4)}
            className={linkClasses(4)}
          >
            <li>Contact us</li>
          </Link>
          <Link to={'tel:918825935922'} className={linkClasses(7)}>
            <li className="flex flex-row gap-2 items-center">
              <MdCall /> <span>+91 88259 35922</span>
            </li>
          </Link>
          <Link
            to={'mailto:hello@mypropertyplus.com'}
            className={linkClasses(8)}
          >
            <li className="flex flex-row gap-2 items-center">
              <MdEmail /> <span>hello@mypropertyplus.com</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
