import React from 'react';
import photo from '../../assets/about.jpg';
import { BsPersonCheckFill } from 'react-icons/bs';
import { MdSavings, MdOutlineDoneOutline } from 'react-icons/md';

function AboutSection() {
  return (
    <div className="flex flex-col items-center justify-center gap-10 pt-20">
      <div className="flex flex-col gap-4 text-center">
        <span className="text-[30px] md:text-[40px] font-semibold">
          Welcome to MountainTree
        </span>
        <span className="text-[15px] md:text-[20px] text-gray-600">
          A way of life
        </span>
      </div>

      <div className="w-auto md:w-[50%] flex flex-row gap-10 px-5 md:px-20 py-5">
        <div className="flex flex-col text-center gap-4">
          <span className="font-semibold">
            MountainTree is a haven where the journey of health, peace, and
            wellness is not just a practice but a way of life. Founded on the
            principles of harmony, mindfulness, and the holistic nurturing of
            the body, mind, and spirit, our store is more than just a
            destination—it's a sanctuary for those seeking tranquility in the
            chaos of everyday life.
          </span>
          <span className="text-gray-700">
            At MountainTree, we believe in the power of connection—not just with
            oneself, but with the world around us. Our carefully curated
            collection of products is designed to foster this connection,
            offering everything from eco-friendly yoga mats and meditation
            cushions to aromatic essential oils, wellness teas, and spiritual
            decor that embodies the essence of serenity and mindfulness.
          </span>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-start items-start gap-10">
        <div className="flex flex-row gap-5 items-center justify-center">
          <div className="text-one text-[40px] md:text-[70px]">
            <BsPersonCheckFill />
          </div>
          <div className="flex flex-col">
            <span className="text-[30px] font-bold text-two">500+</span>
            <span className="text-gray-700">Delighted Customers</span>
          </div>
        </div>

        <div className="flex flex-row gap-5 items-center justify-center">
          <div className="text-one text-[40px] md:text-[70px]">
            <MdOutlineDoneOutline />
          </div>
          <div className="flex flex-col">
            <span className="text-[30px] font-bold text-two">1+</span>
            <span className="text-gray-700">Years of Service</span>
          </div>
        </div>

        <div className="flex flex-row gap-5 items-center justify-center">
          <div className="text-one text-[40px] md:text-[70px]">
            <MdSavings />
          </div>
          <div className="flex flex-col">
            <span className="text-[30px] font-bold text-two">₹10 Lakh</span>
            <span className="text-gray-700">In Savings</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
